<template>
  <!-- 前往結帳Bar -->
  <div class="go_checkout_box">
    <div
      ref="goCheckout"
      id="go_checkout"
      class="go_checkout"
      :class="{ fix : ifFix }">
        <div class="go_checkout_inner">
          <div class="go_checkout_total">
            <router-link :to="{ path: prev }">
              <p class="txt-bold">
                <img src="@/assets/img/icon/icon_next-b-rev.svg" alt="" style="margin-right: 10px;">
                {{prevBtnText}}
              </p>
            </router-link>
            <div v-if="totalPriceAndCount.count > 0 && totalPriceAndCount.total > 0
              && !isCartLoading">
              <p>{{totalPriceAndCount.count}} 樣商品合計</p>
              <p class="h3 txt-orange txt-highbold">
                <vue-numeric
                  read-only currency="$" separator=","
                  :value="totalPriceAndCount.total">
                </vue-numeric>
              </p>
            </div>
          </div>
          <button
             v-if="(this.$route.name === 'CartDetail') && (nextBtnText === '前往結帳')"
             class="btn btn-orange_arrow"
             @click="$emit('onSubmit')"
            >
            <h6 class="txt-white">
              <span>{{nextBtnText}}</span>
              <img src="@/assets/img/icon/icon_next-w.svg" alt="">
            </h6>
          </button>
          <!-- 從 cartInfo -> cartDetail -->
          <button
            v-else @click="$emit('toCartDetail', next)"
            class="btn btn-orange_arrow cartToDetailBtn"
            :class="{active: canCartToDetail}">
            <h6 class="txt-white">
              <span>{{nextBtnText}}</span>
              <img src="@/assets/img/icon/icon_next-w.svg" alt="">
            </h6>
          </button>
        </div>
    </div>
    <div
      id="go_checkout_area"
      :class="{ active : ifFix }"
      :style="{ height: `${areaHeight}px` }">
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .cartToDetailBtn{
    filter: grayscale(100%);
    pointer-events: none;
    user-select: none;
    &.active{
      filter: grayscale(0%);
      pointer-events: all;
    }
  }
</style>

<script>
import { mapState } from 'vuex';

export default {
  name: 'CartButtomBar',
  props: ['ifFix', 'next', 'prev', 'nextBtnText', 'prevBtnText'],
  data() {
    return {
      areaHeight: 0,
      // canCartToDetailD: false,
    };
  },
  computed: {
    ...mapState(
      'moduleCart',
      {
        isCartLoading: 'isCartLoading',
      },
    ),
    ...mapState([
      'totalPriceAndCount',
      'canCartToDetail',
    ]),
  },
  // watch: {
  //   canCartToDetail(val) {
  //     this.canCartToDetailD = val;
  //   },
  // },
  methods: {
    initAreaHeight() {
      this.areaHeight = this.$refs.goCheckout.offsetHeight;
    },
  },
  mounted() {
    this.initAreaHeight();
  },
  updated() {
    this.initAreaHeight();
  },
};
</script>
